@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(252, 88, 165);
  /* outline: 1px solid slategrey; */
  border-radius: 20px;
}

.animated-gradient-bg {
  background: linear-gradient(180deg, #fc58a5, #f9d6e7, #ff51a2, #ffb4d7);
  background-size: 800% 800%;
  -webkit-animation: gradient-animation 6s ease-in-out infinite;
  animation: gradient-animation 6s ease-in-out infinite;
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
